var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(_vm.sopComponent, {
        tag: "component",
        attrs: {
          popupParam: _vm.popupParam,
          sop: _vm.sop,
          rev: _vm.rev,
          isOld: _vm.isOld,
          contentHeight: _vm.contentHeight,
        },
        on: {
          "update:popupParam": function ($event) {
            _vm.popupParam = $event
          },
          "update:popup-param": function ($event) {
            _vm.popupParam = $event
          },
          "update:sop": function ($event) {
            _vm.sop = $event
          },
          "update:rev": function ($event) {
            _vm.rev = $event
          },
          "update:isOld": function ($event) {
            _vm.isOld = $event
          },
          "update:is-old": function ($event) {
            _vm.isOld = $event
          },
          getDetail: _vm.getDetail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }