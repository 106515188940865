<template>
  <div>
    <component
      :is="sopComponent"
      :popupParam.sync="popupParam"
      :sop.sync="sop"
      :rev.sync="rev"
      :isOld.sync="isOld"
      :contentHeight="contentHeight"
      @getDetail="getDetail"
    />
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'sopDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSopId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      sopComponent: () => import(`${'./sopInfo.vue'}`),
      sop: {
        mdmSopId: '',  // 안전작업 표준 일련번호
        plantCd: null,  // 사업장코드
        subProcessCd: '',  // 단위공정 코드
        revNo: '',  // 개정번호
        revReason: '',  // 개정사유
        workTypeCd: null,  // 작업유형 : 특별안전, 일반안전, 단순일상
        workKindCd: null,  // 작업형태 : Gas, 고소, 굴착/매설, 방사선, 전기, 지게차, 기타
        useFlag: 'Y',  // 사용여부
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        regUserId: '',
        deptName: '',
        lineName: '',
        chgUserId: '',
        revSelectValue: null,
        sopSteps: [], // 작업단계 목록
        revs: [], // 개정 목록
        mmEquips: [], // 설비 목록
        mmMachines: [], // 기계기구 목록
        chems: [], // 화학자재 목록
        sopSummary: [], // 요약정보
        sopStepHazards: [], // 작업단계 (w/h. 유해위험요인)
      },
      rev: {
        isRevision: false,
      },
      detailUrl: '',
      revlistUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.mdmSopId)
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.sop.job.get.url;
      this.revlistUrl = selectConfig.mdm.sop.job.revList.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail(mdmSopId) {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, (!mdmSopId ? this.popupParam.mdmSopId : mdmSopId));
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.sop, _result.data);
          this.$set(this.sop, 'revSelectValue', _result.data.mdmSopId)
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    getRevList() {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        groupId: this.sop.groupId
      };
      this.$http.request((_result) => {
        this.$set(this.sop, 'revs', _result.data);
      },);
    },
  }
};
</script>